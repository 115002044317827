import React from 'react'
import '../../Assets/Styles/Footer.css'


const Footer = () => {
  return (
    <div className='footerSec'>
      <h3>Komofar</h3>
      <p className='metaText'>support [@] iglweb.com</p>
      <p className='copyrightTxt'>Design n development by IGL Web Ltd @2024</p>
    </div>
  )
}

export default Footer
