import React, { useState } from 'react'
import '../../Assets/Styles/NavigationBar.css'
import {Navbar,Container,Nav,} from 'react-bootstrap';
import {Link} from "react-router-dom";
import { FaRegUserCircle,FaShoppingBag } from "react-icons/fa";
// import { IoBagRemove } from "react-icons/io5";


function NavigationBar() {

    const [expanded, setExpanded] = useState(false);

  const toggleNavbar = () => {
    setExpanded(!expanded);
  };


  return (
        <div className='container'>
        <div className='row'>
            <div className='4'></div>
            <div className='4'>
            <div className='headingContainsWrapper'>
                <h6>K O M O F A R</h6>
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFXyiYsvaTw35kuDRkHWB0MehzoBllXaHkwPK3gIxRTg&s" alt="headingImage"/><br/>
                <p>Its always the best</p>
            </div>
            </div>
            <div className='4'></div>

            <div class="navBarWrapper">
            <Navbar expand="lg" className="animated navbar-with-zindex" expanded={expanded}>
      <Container fluid>
        <Navbar.Toggle aria-controls="navbarScroll" onClick={toggleNavbar}>
          {expanded ? <span>&times;</span> : <span>&#9776;</span>}
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarScroll">
          <Nav className="center-nav w-100 justify-content-center">
            <Nav.Link className='hvr-float text-center' as={Link} to={'/'}>Home</Nav.Link>
            <Nav.Link className='hvr-float text-center' as={Link} to={'/products/dry'}>Dry and canned product</Nav.Link>
            <Nav.Link className='hvr-float text-center' as={Link} to={'/products/flour'}>Flour</Nav.Link>
            <Nav.Link className='hvr-float text-center' as={Link} to={'/products/hairProduct'}>Hair product</Nav.Link>
            <Nav.Link className='hvr-float text-center' as={Link} to={'/products/kitchenTool'}>Kitchen tool</Nav.Link>
            <Nav.Link className='hvr-float text-center' as={Link} to={'/products/noodles'}>Noodles</Nav.Link>
            <Nav.Link className='hvr-float text-center' as={Link} to={'/products/pasta'}>Pasta</Nav.Link>
            <Nav.Link className='hvr-float text-center' as={Link} to={'/products/rice'}>Rice</Nav.Link>
            <Nav.Link className='hvr-float text-center' as={Link} to={'/products/spices'}>Spices</Nav.Link>
            <Nav.Link className='hvr-float loginInfo text-center' as={Link} to={'/login'}><i><FaRegUserCircle />&nbsp;Log In</i></Nav.Link>
            <Nav.Link className='hvr-float cartInfo text-center' as={Link} to={'/'}><i><FaShoppingBag /></i></Nav.Link>
            {/* <div className='counterCircle'>5</div>  */}
            {/* <Nav.Link className='hvr-float text-center' as={Link} to={'#'}><IoBagRemove />Cart <span className='counterCircle'>5</span> </Nav.Link> */}

            {/* <lebel className="cartCount">2</lebel> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

        </div>
        </div>
        </div>
  )
}

export default NavigationBar
