import React, { useRef } from 'react'
import { FcGoogle } from "react-icons/fc";
import { RxCross1 } from 'react-icons/rx';
import { Link } from 'react-router-dom';
import '../../Assets/Styles/Register.css';
import { FaFacebook } from "react-icons/fa";
import { ToastErrorToast} from '../../Helper/FormHelper2';
import { IsEmpty } from '../../Helper/FormHelper';
import Swal from 'sweetalert2';


const Register = () => {

    let EmailRef,PasswordRef=useRef();

    const OnSubmit=()=>{
        
        let Email=EmailRef.value;
        let Password=PasswordRef.value;
    
    
        if(IsEmpty(Email)){
          ToastErrorToast("Email Is Required");
        }else if(IsEmpty(Password)){
          ToastErrorToast("Password Is Required");
          }else{
            success();
        }
    }


    const success=()=>{
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Signup Success",
            showConfirmButton: false,
            timer: 1500
          });
    }


  return (
    <div className='container'>
        <div className='row'>
        <div className='col-sm-3'></div>
        <div className='col-sm-6'>

            <div className='signupBoxWrapper'>
            <h1>Sign Up</h1>
            <p><i>Already a member?Log In</i></p>
            <div className='emailField mb-3'>
                <p><i>Email</i></p>
                <input ref={(input)=>EmailRef=input} type="email" className='email'/>
            </div>
            <div className='passwordField mb-2'>
                <p><i>Password</i></p>
                <input ref={(input)=>PasswordRef=input} type='password' className='password'/>
            </div>
            <div className='passwordField mb-2'>
                <button onClick={OnSubmit}  className='signUpBoxBtn'>Sign Up</button>
            </div>
            <span><i>or sign up with</i></span>
            <div className='signupWithEmailAndfacebook mt-2'>
                <span> <FcGoogle />&nbsp; &nbsp; <lebel> <FaFacebook /></lebel></span>
            </div>

            </div>
        </div>
        <div className='col-sm-3'>
            <div className='crossBtn'>
            <Link to='/'><RxCross1 /></Link>
            </div>

        </div>
        </div>
    </div>
  )
}

export default Register
