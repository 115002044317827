import React from 'react';
import { ImWhatsapp } from "react-icons/im";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa";
import '../../Assets/Styles/ProductDetails.css';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Footer from './Footer';



const ProductDetails = () => {
  return (
    <div>
      <section className='AllDetailsWrapper'>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='imageDetails animated fadeIn'>
                        {/* <img className='img-fluid' src=""/> */}
                        <Zoom>
                        <img className='img-fluid img-thumbnail' src="https://static.wixstatic.com/media/f23709_f347e2320b8643c7bc8b980a74f701b4~mv2.jpg/v1/fill/w_386,h_580,al_c,lg_1,q_80,enc_auto/f23709_f347e2320b8643c7bc8b980a74f701b4~mv2.jpg" alt=""
                        zoomSrc="https://static.wixstatic.com/media/f23709_f347e2320b8643c7bc8b980a74f701b4~mv2.jpg/v1/fill/w_386,h_580,al_c,lg_1,q_80,enc_auto/f23709_f347e2320b8643c7bc8b980a74f701b4~mv2.jpg"
                        />
                        </Zoom>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='detailsWrapper animated fadeIn'>
                    <h3><i>Ruhunu string hopper flour 700g</i></h3>
                    <p><i>€2,50</i></p>
                    <span><i>Quantity</i></span><br/>
                    <input type="number" min="1"/><br/>
                    <button><i>Add to Cart</i></button>
                    </div>
                    <div className='socialMediaWrapper animated fadeInUp'>
                        <span><ImWhatsapp /></span><span><FaFacebook /></span><span><FaTwitter /></span><span><FaPinterest /></span>
                    </div>
                </div>
            </div>
        </div>
      </section>

      <Footer />
    </div>
  )
}

export default ProductDetails
