import React from 'react'
import NavigationBar from '../Pages/Users/NavigationBar';
import Products from '../Pages/Users/Products';


const ProductsLayout = () => {
  return (
    <div>
      <NavigationBar />
      <Products />
    </div>
  )
}

export default ProductsLayout
