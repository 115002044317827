import React from 'react'
import { Link, useParams } from 'react-router-dom';
import '../../Assets/Styles/Products.css'
import Footer from './Footer';

const Products = () => {

    let {category}=useParams();
    console.log(category);
    

  return (
    <section>

      <div className='products mt-4'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-3'>
                    <div className="card productCard animated fadeIn">
                        <Link to="/productDetails">
                        <img src="https://static.wixstatic.com/media/f23709_4eed27aba1e7404f8cb5ef8d2ee4ccc6~mv2.jpg/v1/fill/w_960,h_960,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/f23709_4eed27aba1e7404f8cb5ef8d2ee4ccc6~mv2.jpg" className="card-img-top" alt="Product" />
                        <div className="card-body">
                            <h5 className="card-title">SAMYANG HOT CHICKEN FLAVOR RAMEN BOW</h5>
                            <h5 className="card-title">€2,80</h5>
                            <div className="row justify-content-center">
                            <button className="btn btn-dark">ADD TO CART</button>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>

                <div className='col-md-3'>
                    <div className="card productCard animated fadeIn">
                    <Link to="/productDetails">
                        <img src="https://static.wixstatic.com/media/f23709_4b374ee74809499da877ae96efe062d2~mv2.jpeg/v1/fill/w_225,h_225,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/f23709_4b374ee74809499da877ae96efe062d2~mv2.jpeg" className="card-img-top" alt="Product" />
                        <div className="card-body">
                            <h5 className="card-title">SAMYANG HOT CHICKEN FLAVOR RAMEN BOW</h5>
                            <h5 className="card-title">€2,80</h5>
                            <div className="row justify-content-center">
                            <button className="btn btn-dark">ADD TO CART</button>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>

                <div className='col-md-3'>
                    <div className="card productCard animated fadeIn">
                    <Link to="/productDetails">
                        <img src="https://static.wixstatic.com/media/f23709_95697bbc7ae2400aad7fcf51dd6a402c~mv2.jpg/v1/fill/w_225,h_225,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/f23709_95697bbc7ae2400aad7fcf51dd6a402c~mv2.jpg" className="card-img-top" alt="Product" />
                        <div className="card-body">
                            <h5 className="card-title">SAMYANG HOT CHICKEN FLAVOR RAMEN BOW</h5>
                            <h5 className="card-title">€2,80</h5>
                            <div className="row justify-content-center">
                            <button className="btn btn-dark">ADD TO CART</button>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>

                <div className='col-md-3'>
                    <div className="card productCard animated fadeIn">
                    <Link to="/productDetails">
                        <img src="https://static.wixstatic.com/media/f23709_c01db45d90b748cf82e52281a97370d0~mv2.jpg/v1/fill/w_225,h_225,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/f23709_c01db45d90b748cf82e52281a97370d0~mv2.jpg" className="card-img-top" alt="Product" />
                        <div className="card-body">
                            <h5 className="card-title">SAMYANG HOT CHICKEN FLAVOR RAMEN BOW</h5>
                            <h5 className="card-title">€2,80</h5>
                            <div className="row justify-content-center">
                            <button className="btn btn-dark">ADD TO CART</button>
                            </div>
                        </div>
                        </Link>
                    </div>
                    
                </div>
            </div>



            <div className='row'>
                <div className='col-md-3'>
                    <div className="card productCard animated fadeIn">
                    <Link to="/productDetails">
                        <img src="https://static.wixstatic.com/media/f23709_5465838ec41444608b9be4057496acfb~mv2.png/v1/fill/w_225,h_225,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/f23709_5465838ec41444608b9be4057496acfb~mv2.png" className="card-img-top" alt="Product" />
                        <div className="card-body">
                            <h5 className="card-title">SAMYANG HOT CHICKEN FLAVOR RAMEN BOW</h5>
                            <h5 className="card-title">€2,80</h5>
                            <div className="row justify-content-center">
                            <button className="btn btn-dark">ADD TO CART</button>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>

                <div className='col-md-3'>
                    <div className="card productCard animated fadeIn">
                    <Link to="/productDetails">
                        <img src="https://static.wixstatic.com/media/f23709_3f9e7f54c9c64ee9aff300a55f731d61~mv2.jpg/v1/fill/w_225,h_225,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/f23709_3f9e7f54c9c64ee9aff300a55f731d61~mv2.jpg" className="card-img-top" alt="Product" />
                        <div className="card-body">
                            <h5 className="card-title">SAMYANG HOT CHICKEN FLAVOR RAMEN BOW</h5>
                            <h5 className="card-title">€2,80</h5>
                            <div className="row justify-content-center">
                            <button className="btn btn-dark">ADD TO CART</button>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>

                <div className='col-md-3'>
                    <div className="card productCard animated fadeIn">
                    <Link to="/productDetails">
                        <img src="https://static.wixstatic.com/media/f23709_da367ab76299427cac7177aed7df9121~mv2.jpg/v1/fill/w_225,h_225,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/f23709_da367ab76299427cac7177aed7df9121~mv2.jpg" className="card-img-top" alt="Product" />
                        <div className="card-body">
                            <h5 className="card-title">SAMYANG HOT CHICKEN FLAVOR RAMEN BOW</h5>
                            <h5 className="card-title">€2,80</h5>
                            <div className="row justify-content-center">
                            <button className="btn btn-dark">ADD TO CART</button>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>

                <div className='col-md-3'>
                    <div className="card productCard animated fadeIn">
                    <Link to="/productDetails">
                        <img src="https://static.wixstatic.com/media/f23709_397d17b15a3848c1b4f581c1b1951ea2~mv2.jpg/v1/fill/w_225,h_225,al_c,lg_1,q_80,enc_auto/f23709_397d17b15a3848c1b4f581c1b1951ea2~mv2.jpg" className="card-img-top" alt="Product" />
                        <div className="card-body">
                            <h5 className="card-title">SAMYANG HOT CHICKEN FLAVOR RAMEN BOW</h5>
                            <h5 className="card-title">€2,80</h5>
                            <div className="row justify-content-center">
                            <button className="btn btn-dark">ADD TO CART</button>
                            </div>
                        </div>
                        </Link>
                    </div>
                    
                </div>
            </div>


        </div>
      </div>


      <Footer />
    </section>
    
  )
}

export default Products
