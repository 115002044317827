import React, { Fragment, useRef } from 'react'
import '../../Assets/Styles/Home.css'
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import { Link,} from 'react-router-dom';
import Footer from './Footer';
import { IsEmpty } from '../../Helper/FormHelper';
import { ToastErrorToast } from '../../Helper/FormHelper2';
import Swal from 'sweetalert2';





const Home = () => {

  let NameRef,PhoneRef,EmailRef,AddressRef,SubjectRef,MessageRef=useRef();


  const OnSubmit=()=>{
        
    let Name=NameRef.value;
    let Phone=PhoneRef.value;
    let Email=EmailRef.value;
    let Address=AddressRef.value;
    let Subject=SubjectRef.value;
    let Message=MessageRef.value;


    if(IsEmpty(Name)){
      ToastErrorToast("Name Is Required");
    }else if(IsEmpty(Email)){
      ToastErrorToast("Email Is Required");
    }else if(IsEmpty(Phone)){
      ToastErrorToast("Phone Is Required");
    }else if(IsEmpty(Address)){
      ToastErrorToast("Address Is Required");
    }else if(IsEmpty(Subject)){
      ToastErrorToast("Subject Is Required");
    }else if(IsEmpty(Message)){
          ToastErrorToast("Message Is Required");
      }else{
        success();
    }
}



    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 1,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
          slidesToSlide: 1,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1,
        },
      };



      const success=()=>{
        Swal.fire({
          title: "Data Submit Successful",
          text: "You clicked the button!",
          icon: "success"
        });
    }
    
  return (

    <Fragment>
      <section className='coverWrapper'>
      <div className='text-center animated fadeIn'>
          <div className='container'>
              <div className='row'>
                  <div className='col-md-2'></div>
                  <div className='col-md-8'>
                      <h2 className="textContentWrapper">WELCOME THE WORLD OF THE BEST ASIAN PRODUCTS</h2>
                      {/* <p className="coverText">FREE DELIVERY IN CYPRUS</p> */}
                  </div>
                  <div className='col-md-2'></div>
              </div>
          </div>
      </div>
      </section>


<section className='slider'>
        <div className='category mt-4 mb-4'>
            <Carousel
              responsive={responsive}
              autoPlay={true}
              infinite={true}
              removeArrowOnDeviceType={['tablet', 'mobile']}
              autoPlaySpeed={1000}
            >
              <div className='hvr-pulse-shrink'>
                <Link to='/productDetails'>
                <div className='allItems'>
              <div className="border-0">
                <img className="card-img-top img-fluid img-thumbnail border-0" src="https://static.wixstatic.com/media/f23709_13a16758e99a41aab04ff694c8c6f6e7~mv2.jpg/v1/fill/w_315,h_315,al_c,lg_1,q_80,enc_auto/f23709_13a16758e99a41aab04ff694c8c6f6e7~mv2.jpg" alt="rent" />
                <div className="">
                  <h5 className="text-center">mr brown zero sugar€1,50Price</h5>
                </div>
              </div>
            </div>
                </Link> 
              </div>
              <div className='hvr-pulse-shrink'>
                <Link to='/productDetails'>
                  <div className='allItems'>
                    <div className="border-0">
                      <img className="card-img-top img-fluid img-thumbnail border-0" src="https://static.wixstatic.com/media/f23709_68baaddc43a4492a86f3323a82de8c3e~mv2.jpg/v1/fill/w_315,h_315,al_c,lg_1,q_80,enc_auto/f23709_68baaddc43a4492a86f3323a82de8c3e~mv2.jpg" alt="apartment" />
                      <div className="">
                        <h5 className="card-title text-center">lipton green lemoni 330ml €1,00Price</h5>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='hvr-pulse-shrink'>
                <Link to='/productDetails'>
                  <div className='allItems'>
                    <div className="border-0">
                      <img className="card-img-top img-fluid img-thumbnail border-0" src="https://static.wixstatic.com/media/f23709_d9d21a502a144d0e852190a5c55de473~mv2.jpg/v1/fill/w_315,h_315,al_c,lg_1,q_80,enc_auto/f23709_d9d21a502a144d0e852190a5c55de473~mv2.jpg" alt="bachelor" />
                      <div className="">
                        <h5 className="card-title text-center">lipton peach 500ml €1,65Price</h5>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='hvr-pulse-shrink'>
                <Link to='/productDetails'>
                  <div className='allItems'>
                    <div className="border-0">
                      <img className="card-img-top img-fluid img-thumbnail border-0" src="https://static.wixstatic.com/media/f23709_42e0fffb6b564a4a81cf7e2f4a3d1d65~mv2.png/v1/fill/w_251,h_251,al_c,lg_1,q_85,enc_auto/f23709_42e0fffb6b564a4a81cf7e2f4a3d1d65~mv2.png" alt="family" />
                      <div className="">
                        <h5 className="card-title text-center">lipton peach 330ml €1,00Price</h5>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='hvr-pulse-shrink'>
                <Link to='/productDetails'>
                  <div className='allItems'>
                    <div className="border-0"> 
                      <img className="card-img-top img-fluid img-thumbnail border-0" src="https://static.wixstatic.com/media/f23709_1fb24abcbb964da28ede2a76cc177aff~mv2.jpg/v1/fill/w_315,h_315,al_c,lg_1,q_80,enc_auto/f23709_1fb24abcbb964da28ede2a76cc177aff~mv2.jpg" alt="unit" />
                      <div className="">
                        <h5 className="card-title text-center">lipton peach 330ml €1,00Price</h5>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='hvr-pulse-shrink'>
                <Link to='/productDetails'>
                  <div className='allItems'>
                    <div className="border-0">
                      <img className="card-img-top img-fluid img-thumbnail border-0" src="https://static.wixstatic.com/media/f23709_160f130405fd4fcfa9fe25ce0596d0e3~mv2.jpg/v1/fill/w_315,h_315,al_c,lg_1,q_80,enc_auto/f23709_160f130405fd4fcfa9fe25ce0596d0e3~mv2.jpg" alt="level" />
                      <div className="">
                        <h5 className="card-title text-center">lipton lemoni 330ml €1,00Price</h5>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </Carousel>
          </div>
</section>

<section className='storyOfkomofar'>
    <div className='container'>
    <div className='row'>
        <div className='col-lg-8 offset-lg-2'>
            <h6>THE STORY OF KOMOFAR</h6>
            <p className='storyMeta'><i>Smart, Affordable Products</i></p>
            <p className='storyMetaLarge'>We founded Komofar with one goal in mind: providing a high-quality, smart, and reliable online store. Our passion for excellence has driven us from the beginning, and continues to drive us into the future. We know that every product counts, and strive to make the entire shopping experience as rewarding as possible. Check it out for yourself!</p>
        </div>
    </div>
    </div>
</section>


<section className='recipeWrapper'>

</section>

<section className='getintouchWrapper'>
    <div className='container'>
        <div className='row'>
            <div className='col-sm-6'>
            <div className='getInTauchContains text-center'>
            <h2>GET IN TOUCH</h2>
            <p>Agiou Andreou 123, Limassol, Cypern</p>
            <p>komofarltd@outlook.com</p>
            <p>+35796822053</p>
            <p>OPEN EVERYDAY </p>
            <p>08.00 - 22.00</p>
            </div>
            </div>
            <div className='col-sm-6'>
                <div className='row'>
                    <div className='col-sm-6'>
                    <p>Name*</p>
                    <input type='text' ref={(input)=>NameRef=input} placeholder="Enter Your Name" className='form-control'/>
                    <p>Phone*</p>
                    <input type='number' max={11} ref={(input)=>PhoneRef=input} placeholder="Enter Your Phone" className='form-control'/>
                    </div>
                    <div className='col-sm-6'>
                    <p>Email*</p>
                    <input type='email' ref={(input)=>EmailRef=input} placeholder="Enter Your Email" className='form-control'/>
                    <p>Address*</p>
                    <input type="text" ref={(input)=>AddressRef=input} placeholder="Enter Your Address" className='form-control'/>
                    </div>
                </div>

                    <p>Subject*</p>
                    <input type="text" ref={(input)=>SubjectRef=input} placeholder="Type your Subject" className='form-control'/>

                    <p>Message*</p>
                    <input type="text" ref={(input)=>MessageRef=input} placeholder="Type your Message Here" className='form-control'/>

                    <button onClick={OnSubmit}  className='btn btn bg-dark text-light form-control'><i>Submit</i></button>
            </div>
        </div>
    </div>
</section>

    <section class="googleMap">
    <div className="map-column">
    <div className="map-wrapper">
      <iframe
        className="map-iframe"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.1908943307285!2d90.37589707619298!3d23.74057117867715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b9c9455e69fd%3A0x8b96dab806c1ddd4!2sStudent%20Visa%20BD!5e0!3m2!1sen!2sbd!4v1707367637172!5m2!1sen!2sbd"
        title="Google Map"
        loading="lazy"
        allowFullScreen=""
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    </div>
    </section>

    <section className='subscribeWrapper'>
        <h3>Subscribe Form</h3>
        <div className='container'>
    <div className='row'>
        <div className="col-sm-3"></div>
        <div className="col-sm-6">
            <div className='input-group'>
                <div className='inputWrapper'>
                <input type="email" className='' placeholder="e.g., email@example.com" />
                <button className='btn btn-dark text-light' type='button'><i>Join</i></button>
                {/* <div><button className='ExtraJoinButton btn btn-dark text-light' type='button'><i>Join</i></button></div> */}
                </div>
            </div>
{/* 
            <h3 className='footerKomo'>Komofar</h3>
            <p className='text-center'>komofarltd@outlook.com</p>
            <p className='text-center'>©2023 by STAN Media</p> */}
        </div>
        <div className="col-sm-3"></div>
    </div>
    </div>

    </section>


    <Footer />
    </Fragment>
  )
}

export default Home
