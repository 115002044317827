import React from 'react';
import NavigationBar from '../Pages/Users/NavigationBar';
import Home from '../Pages/Users/Home';


const HomeLayout = () => {
  return (
    <div>
      <NavigationBar />
      <Home/>
    </div>
  )
}

export default HomeLayout
