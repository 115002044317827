import React from 'react';
import ProductDetails from '../Pages/Users/ProductDetails';
import NavigationBar from '../Pages/Users/NavigationBar';

const ProductDetailsLayout = () => {
  return (
    <div>
      <NavigationBar />
      <ProductDetails/>
    </div>
  )
}

export default ProductDetailsLayout
