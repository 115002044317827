import React from 'react'
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import '../../Assets/Styles/Login.css';
import { RxCross1 } from "react-icons/rx";
import { Link } from 'react-router-dom';




const Login = () => {

  return (
    <div className='container'>
        <div className='row'>
        <div className='col-sm-3'></div>
        <div className='col-sm-6 '>

            <div>
                <div className='signupBoxWrapper'>
            <h1>Sign In</h1>
            <p><i>Already a member?Log In</i></p>
            <div className='signupWithGoogle mb-3'>
                <span><FcGoogle /> Sign up with Google</span>
            </div>
            <div className='signupWithFacebook mb-2'>
                <span><FaFacebook /> Sign up with Facebook</span>
            </div>
            <span><i>or</i></span>
            <Link to="/register">
            <div className='signupWithEmail mt-2'>
                <span>Sign up with email</span>
            </div>
            </Link>
            
            </div>
            </div>
        </div>
        <div className='col-sm-3'>
            <div className='crossBtn'>
            <Link to='/'><RxCross1 /></Link>
            </div>

        </div>
        </div>
    </div>
  )
}

export default Login
