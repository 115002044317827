import { Fragment } from "react";
import HomeLayout from "./Layout/HomeLayout";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProductsLayout from "./Layout/ProductsLayout";
import Login from './Pages/Users/Login';
import Register from "./Pages/Users/Register";
import ProductDetailsLayout from "./Layout/ProductDetailsLayout";


function App() {
  return (
    <Fragment>
        <BrowserRouter>
        
        <Routes>
          {/* For User */}
          <Route path="/" element={<HomeLayout />} />
          <Route path="/products/:category" element={<ProductsLayout />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/productDetails" element={<ProductDetailsLayout />} />      
          
        </Routes>

        </BrowserRouter>
      </Fragment>
  );
}

export default App;

